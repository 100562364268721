import React from 'react'

import './mic.scss'
import Icon from 'src/img/icons/mic.svg'

class Mic extends React.Component {

    constructor(){
        super();
        this.state = {
            active: false
        }
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.props.active ? this.setState({active: true}) : null
    }

    componentDidUpdate(prevProps) {
        if (prevProps.active !== this.props.active) {
            this.props.active ? this.setState({active: true}) : this.setState({active: false})
        }
      }

    handleClick () {
        console.log('child clicked')
        this.props.clicked(true)
    }

    render () {
        const { active } = this.state
        return (
            <button disabled={this.props.disabled} className={"btn btn-action btn-primary btn-mic s-circle" + (active ? " active" : "")} onClick={this.handleClick}>
                <Icon />
            </button>
        )
    }
}

export default Mic