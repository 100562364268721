import React from "react"
import { StaticQuery, graphql } from "gatsby"

import './sponsors.scss'

const Sponsors = () => (
  <StaticQuery
    query={graphql`
      {
        allWordpressAcfOptions {
          edges {
            node {
              id
              options {
                sponsors {
                  logos {
                    logo {
                      #url {
                      #  source_url
                      #  id
                      #}
                      media_details {
                        sizes {
                          full {
                            source_url
                          }
                        }
                      }
                    }
                    url
                  }
                  title
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
        return (
            <div className="sponsors container">
                {/* title */}
                {data && 
                    data.allWordpressAcfOptions &&
                    data.allWordpressAcfOptions.edges &&
                    data.allWordpressAcfOptions.edges[0] &&
                    data.allWordpressAcfOptions.edges[0].node &&
                    data.allWordpressAcfOptions.edges[0].node.options &&
                    data.allWordpressAcfOptions.edges[0].node.options.sponsors &&
                    data.allWordpressAcfOptions.edges[0].node.options.sponsors.title
                    ? <h2 className="title">{data.allWordpressAcfOptions.edges[0].node.options.sponsors.title}</h2> : null}
                {/* logos */}
                <div className="columns">
                {data && 
                    data.allWordpressAcfOptions &&
                    data.allWordpressAcfOptions.edges &&
                    data.allWordpressAcfOptions.edges[0] &&
                    data.allWordpressAcfOptions.edges[0].node &&
                    data.allWordpressAcfOptions.edges[0].node.options &&
                    data.allWordpressAcfOptions.edges[0].node.options.sponsors &&
                    data.allWordpressAcfOptions.edges[0].node.options.sponsors.logos && 
                    data.allWordpressAcfOptions.edges[0].node.options.sponsors.logos.map(
                        (prop, i) => {
                        //   const img = <img src={prop.logo.url.source_url} />
                          const img = <img src={prop.logo.media_details.sizes.full.source_url} className="img-responsive" />
                          const logo = prop.url ? <a href={prop.url} >{img}</a> : img
                          return (
                            <div key={"col-"+i} className="column col-sm-6 col-3">
                              {logo}
                            </div>
                          )
                        }
                    )
                }
                </div>
                {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
            </div>
        )
    }}
  ></StaticQuery>
)

// const Sponsors = () => (<></>)

export default Sponsors

