// See: https://github.com/robmarshall/gatsby-gravityforms-component
import React from 'react'
// import { Redirect } from '@reach/router'
import { navigate } from "gatsby"

import AudioTranscriber from 'src/components/audio/AudioTranscriber';
// import Player from 'src/components/player/player'
import Loader from 'src/components/speak-loader/loader'
import Header from 'src/components/speak-header/header'
// import Recorder from 'src/components/audio/Recorder'
import Recorder from 'src/components/speak-recorder/recorder'
import Survey from 'src/components/speak-survey/survey'
import Welcome from 'src/components/speak-welcome/welcome'
import Head from 'src/components/seo/seo'
import Div100vh from 'react-div-100vh'

import './speak.scss'

const colors = [
    'bg-purple-light',
    'bg-pink-light',
    'bg-purple-light',
    'bg-pink-light',
    'bg-purple',
]

export default class audioPage extends React.Component {

    listen = null

    constructor(){
        super();
        this.state = {
            active: 0,
            audio: null,
            bgColor: 'bg-purple-light',
            error: null,
            transcribing: false,
            userData: {
                terms: null,
                generation: null,
                title: null,
                file: null,
                complete: false
            }
        }
        this.handleAudio = this.handleAudio.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTranscription = this.handleTranscription.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this)
    }

    handleAudio(audio) {
        this.setState({audio: audio})
    }

    handleChange (data) {
        const active = this.state.active == 4 ? 0 : ++this.state.active
        this.setState({
            active: active,
            bgColor: colors[active],
            userData: {...this.state.userData, ...data}
        });
        console.log(this.state.userData)
    }

    handleTranscription(data) {
        // console.log(data)
        const title = data.transcript
        // console.log(data)
        // console.log(data.transcript)
        const userData = {
            title: title
        }
        data.transcript ? this.setState({userData: {...this.state.userData, ...{title:title}}}) : null
        data.transcribing===false ? this.setState({transcribing:false, userData: {...this.state.userData, ...{complete:true}}}) : null
    }

    handleRedirect(id) {
        console.log('redirecting')
        // console.log(url)
        // this.setState({active: 4})
        navigate(
            "/listen/",
            {
              state: { 
                  hideRec: true,
                  setTrack: id
                },
            }
          )
    }

    render () {
        return (
            <>
            <Head title="18x18 by YWCA" />
            <Div100vh className={"page page-speak " + this.state.bgColor}>
                <Header />
                {
                    this.state.active == 1 ? (
                        <Recorder next={this.handleChange} audio={this.handleAudio} />
                    ) : this.state.active == 2 ? (
                        <Survey next={this.handleChange} />
                    ) : this.state.active == 3 ? (
                        <Loader data={this.state.userData} redirect={this.handleRedirect} />
                    // ) : this.state.active == 4 ? (
                    //     <Redirect noThrow to="/listen/" />
                    ) : 
                        <Welcome next={this.handleChange} />
                }
                <AudioTranscriber audio={this.state.audio} transcription={this.handleTranscription} />
            </Div100vh>
            </>
        )
    }
}