import React, { Component } from 'react';

import variables from 'src/styles/_variables.scss'
import './AudioVisualiser.scss'

class AudioVisualiser extends Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
  }

  componentDidUpdate() {
    this.props ? this.draw() : false
  }

  draw() {
    
    // console.log(this.props);

    const { audioData } = this.props;
    const canvas = this.canvas.current;
    const height = canvas.height;
    const width = canvas.width;
    const context = canvas.getContext('2d');
    let x = 0;
    const sliceWidth = (width * 1.0) / audioData.length;

    context.lineWidth = 2;
    // context.strokeStyle = variables.primaryColor;
    context.strokeStyle = "#000";
    context.clearRect(0, 0, width, height);

    context.beginPath();
    context.moveTo(0, height / 2);
    for (const item of audioData) {
      const y = (item / 255.0) * height;
      context.lineTo(x, y);
      x += sliceWidth;
    }
    context.lineTo(x, height / 2);
    context.stroke();
  }

  render() {
    const className = this.props.className
    const canvasStyle = {
      height: "810px",
      width: "1440px"
    }

    return <canvas height="810" width="1440" style={canvasStyle} ref={this.canvas} className={"visualizer " + className} />;
  }
}

export default AudioVisualiser;
