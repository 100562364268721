import React from 'react'
import { Router } from "@reach/router"

import BlogPost from 'src/templates/post'
  
const App = () => (
    <Router>
        <BlogPost path="/stories/:slug" />
    </Router>
)

export default App