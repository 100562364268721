import React from "react"
import { Helmet } from "react-helmet"

const Seo = ({ title }) => {
    return (
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>

          <meta
            property="og:url"
            content={"https://" + process.env.HOST}
          />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="18x18 Immersive Exhibition" />
          <meta
            property="og:description"
            content="It's real. It's raw. It's a rollercoaster."
          />
          <meta
            property="og:image"
            content="https://s3-us-east-2.amazonaws.com/18at18-dev/app/uploads/2019/11/fb-share-image.jpg"
          />

          <link
            rel="apple-touch-icon"
            sizes="57x57"
            href="/apple-icon-57x57.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="60x60"
            href="/apple-icon-60x60.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="72x72"
            href="/apple-icon-72x72.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="76x76"
            href="/apple-icon-76x76.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="114x114"
            href="/apple-icon-114x114.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="120x120"
            href="/apple-icon-120x120.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href="/apple-icon-144x144.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="152x152"
            href="/apple-icon-152x152.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-icon-180x180.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href="/android-icon-192x192.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="96x96"
            href="/favicon-96x96.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/manifest.json" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
          <meta name="theme-color" content="#ffffff" />

          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-74789904-19"
          ></script>
          <script>{`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments)};
                gtag('js', new Date());
                gtag('config', 'UA-74789904-19');
            `}</script>
        </Helmet>
      </div>
    )
}
export default Seo