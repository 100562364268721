import React from 'react'
import { Link } from 'gatsby'

import './record.scss'
import Icon from 'src/img/icons/mic.svg'

const Record = (props) => (
    <Link to="/speak/" className="btn-record-wrapper">
        <span className="label label-rounded hide-md">Add your voice</span>
        <button className={"btn btn-action btn-record s-circle" + (props.className ? " " + props.className : null)}>
            <Icon />
        </button>
    </Link>

)

export default Record