import React from "react"
import { Textfit } from 'react-textfit';

import './text.scss'

class Text extends React.Component {

    constructor(props){
        super(props)
    }

    render() {
      return (
        <Textfit max={999} mode="single" className="text">
          {this.props.text}
        </Textfit>
      );
    }
  }

  export default Text