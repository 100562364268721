// https://www.npmjs.com/package/@aws-sdk/client-transcribe-browser
import React, { Component } from 'react';
import AWS from 'aws-sdk';
// import TranscribeService from "aws-sdk/clients/transcribeservice";
import { TranscribeClient, StartStreamTranscription } from "@aws-sdk/client-transcribe-browser";

const audioUtils        = require('./lib/audioUtils');  // for encoding audio data as PCM
const crypto = require('crypto'); // tot sign our pre-signed URL
const v4 = require('./lib/aws-signature-v4'); // to generate our pre-signed URL
const marshaller        = require("@aws-sdk/eventstream-marshaller"); // for converting binary event stream messages to and from JSON
const util_utf8_node    = require("@aws-sdk/util-utf8-node"); // utilities for encoding and decoding UTF8
const mic               = require('microphone-stream'); // collect microphone input as a stream of raw bytes

// our converter between binary event streams messages and JSON
const eventStreamMarshaller = new marshaller.EventStreamMarshaller(util_utf8_node.toUtf8, util_utf8_node.fromUtf8);

class AudioTranscriber extends Component {
  
  // albumBucketName = '18at18-dev'
  // languageCode = "en-GB"
  poolId = 'ap-southeast-2:0a650ea6-2403-4777-9752-e202a4ae9d2f'
  // region = "ap-southeast-2"
  // sampleRate = 8000
  // socket
  // source

  // our global variables for managing state
  languageCode = "en-GB"
  region = "ap-southeast-2"
  sampleRate = 8000
  transcription = "transcription"
  socket
  micStream
  socketError = false
  transcribeException = false



  constructor(props) {
    super(props)
    this.state = {
      active: false,
      transcription: ''
    }
  }

  componentDidMount() {
    console.log('transcriber did mount')

    // Initialize the Amazon Cognito credentials provider
    AWS.config.region = this.region; // Region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.poolId,
    });

    // console.log(AWS.config.credentials);
    // this.streamAudioToWebSocket()

    // this.getCredentials()      
    this.props.audio ? this.start() : null

  }

  start() {
    console.log('transcriber did start')

    this.setState({active: true})
    this.streamAudioToWebSocket()
  }

  stop() {
    // console.log('mic stream did stop')

    setTimeout(function () {
      console.log('mic stream did stop after 1 seconds')
      this.setState({active: false})
      this.micStream.stop() // stop mic
      // this.socket.close() // close socket
      setTimeout(function () {
        console.log('socket did close after 2 seconds')
        // this.setState({active: false})
        // this.micStream.stop() // stop mic
        this.socket.close() // close socket
        this.props.transcription({transcribing: false}); // notify ancestors of end

      }.bind(this), 4000);
    }.bind(this), 1000);

  }

  componentDidUpdate(prevProps) {
    console.log('transcriber did update')

    if (prevProps.audio !== this.props.audio) {
      this.props.audio ? this.start() : this.stop()
    }
  }

  async streamAudioToWebSocket() {
    console.log('streamAudioToWebSocket did run')
    // https://docs.aws.amazon.com/transcribe/latest/dg/websocket.html#websocket-url

    //let's get the mic input from the browser, via the microphone-stream module
    this.micStream = new mic();
    this.micStream.setStream(this.props.audio);

    // console.log(this.micStream);

    // Pre-signed URLs are a way to authenticate a request (or WebSocket connection, in this case)
    // via Query Parameters. Learn more: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
    let url = await this.createPresignedUrl();

    //open up our WebSocket connection
    this.socket = new WebSocket(url);
    this.socket.binaryType = "arraybuffer";

    // when we get audio data from the mic, send it to the WebSocket if possible
    this.socket.onopen = function() {
        // console.log('transcriber socket open')
        this.micStream.on('data', function(rawAudioChunk) {
            // console.log('transcriber stream data')
            // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
            let binary = this.convertAudioToBinaryMessage(rawAudioChunk);

            if (this.socket.OPEN)
                console.log('transcriber socket sending')
                this.socket.send(binary);
        }.bind(this)
    )}.bind(this)

    // handle messages, errors, and close events
    this.wireSocketEvents();
  }

  convertAudioToBinaryMessage(audioChunk) {

    let raw = mic.toRaw(audioChunk);

    if (raw == null)
        return;

    // downsample and convert the raw audio bytes to PCM
    let downsampledBuffer = audioUtils.downsampleBuffer(raw, this.sampleRate);
    let pcmEncodedBuffer = audioUtils.pcmEncode(downsampledBuffer);

    // add the right JSON headers and structure to the message
    let audioEventMessage = this.getAudioEventMessage(Buffer.from(pcmEncodedBuffer));

    //convert the JSON object + headers into a binary event stream message
    let binary = eventStreamMarshaller.marshall(audioEventMessage);

    return binary;
  }

  async createPresignedUrl() {
    let endpoint = "transcribestreaming." + this.region + ".amazonaws.com:8443";

    await this.getCredentials();

    // get a preauthenticated URL that we can use to establish our WebSocket
    return v4.createPresignedURL(
        'GET',
        endpoint,
        '/stream-transcription-websocket',
        'transcribe',
        crypto.createHash('sha256').update('', 'utf8').digest('hex'), {
            'key': AWS.config.credentials.accessKeyId,
            'secret': AWS.config.credentials.secretAccessKey,
            'sessionToken': AWS.config.credentials.sessionToken,
            'protocol': 'wss',
            'expires': 15,
            'region': this.region,
            'query': "language-code=" + this.languageCode + "&media-encoding=pcm&sample-rate=" + this.sampleRate
        }
    );
  }

  getAudioEventMessage(buffer) {
    // wrap the audio data in a JSON envelope
    return {
        headers: {
            ':message-type': {
                type: 'string',
                value: 'event'
            },
            ':event-type': {
                type: 'string',
                value: 'AudioEvent'
            }
        },
        body: buffer
    };
  }

  getCredentials() {
    // Set the region where your identity pool exists (us-east-1, eu-west-1)
    AWS.config.region = this.region;

    // Configure the credentials provider to use your identity pool
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      AllowUnauthenticatedIdentities: true,
      IdentityPoolId: this.poolId,
      // Logins: { // optional tokens, used for authenticated login
      //     'graph.facebook.com': 'FBTOKEN',
      //     'www.amazon.com': 'AMAZONTOKEN',
      //     'accounts.google.com': 'GOOGLETOKEN'
      // }
    });

    // Make the call to obtain credentials
    AWS.config.credentials.get(function(){

        // Credentials will be available when this function is called.
        var accessKeyId = AWS.config.credentials.accessKeyId;
        var secretAccessKey = AWS.config.credentials.secretAccessKey;
        var sessionToken = AWS.config.credentials.sessionToken;

        // console.log(accessKeyId);
        // console.log(secretAccessKey);
        // console.log(sessionToken);
    });
  }

  handleEventStreamMessage = function (messageJson) {
    let results = messageJson.Transcript.Results;

    if (results.length > 0) {
        if (results[0].Alternatives.length > 0) {
            let transcript = results[0].Alternatives[0].Transcript;

            // fix encoding for accented characters
            transcript = decodeURIComponent(escape(transcript));

            // console.log('transcriber did transcribe');
            // console.log(this.transcription);
            // this.setState({transcription: transcript})
            // pass to parent component
            this.props.transcription({
              transcript: transcript,
              partial: results[0].IsPartial ? true : false
            })

            // close socket if audio is null and transcript has ended
            // if(!this.state.active && !results[0].IsPartial) {
            //   // wait 5 seconds to be sure
            //   setTimeout(function () {
            //     console.log('transcriber socket closed after 5 seconds')
            //     // this.socket.close()
            //   }, 5000);
            // }
            // // update the textarea with the latest result
            // $('#transcript').val(transcription + transcript + "\n");

            // // if this transcript segment is final, add it to the overall transcription
            // if (!results[0].IsPartial) {
            //     //scroll the textarea down
            //     $('#transcript').scrollTop($('#transcript')[0].scrollHeight);

            //     transcription += transcript + "\n";
            // }
        }
    }
}

  showError(message) {
    console.log(message)
  }

  wireSocketEvents() {
    // handle inbound messages from Amazon Transcribe
    this.socket.onmessage = function (message) {
        //convert the binary event stream message to JSON
        let messageWrapper = eventStreamMarshaller.unmarshall(Buffer(message.data));
        let messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));
        if (messageWrapper.headers[":message-type"].value === "event") {
            this.handleEventStreamMessage(messageBody);
        }
        else {
            this.transcribeException = true;
            this.showError(messageBody.Message);
            // toggleStartStop();
        }
    }.bind(this)

    this.socket.onerror = function () {
        this.socketError = true;
        this.showError('WebSocket connection error. Try again.');
        // toggleStartStop();
    }.bind(this)
    
    this.socket.onclose = function (closeEvent) {
        this.micStream.stop();
        
        // the close event immediately follows the error event; only handle one.
        if (!this.socketError && !this.transcribeException) {
            if (closeEvent.code != 1005) {
                // this.showError('</i><strong>Streaming Exception</strong><br>' + closeEvent.reason);
                console.log('Streaming Exception: ', closeEvent)
            }
            // toggleStartStop();
        }
    }.bind(this)
  }

  /* Render */
  render() {
    return null
  }

}

export default AudioTranscriber;