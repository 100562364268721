// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-alignwide-js": () => import("./../src/templates/alignwide.js" /* webpackChunkName: "component---src-templates-alignwide-js" */),
  "component---src-templates-home-js": () => import("./../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-listen-js": () => import("./../src/pages/listen.js" /* webpackChunkName: "component---src-pages-listen-js" */),
  "component---src-pages-speak-js": () => import("./../src/pages/speak.js" /* webpackChunkName: "component---src-pages-speak-js" */),
  "component---src-pages-stories-js": () => import("./../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */)
}

