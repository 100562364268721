import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Carousel from 'src/components/carousels/carousel'

// import './slick.css'
import './carousels.scss'

const StoriesCarousel = () => (
  <StaticQuery
    query={graphql`
    {
      allWordpressAcfOptions {
        edges {
          node {
            id
            options {
              carousel_content
            }
          }
        }
      }
      allWordpressWpVoice(filter: {acf: {images: {slide_background: {source_url: {ne: null}}, slide_foreground: {source_url: {ne: null}}}}}) {
        edges {
          node {
            acf {
              images {
                slide_background {
                  media_details {
                    sizes {
                      large {
                        source_url
                      }
                      medium {
                        source_url
                      }
                    }
                  }
                }
                slide_foreground {
                  media_details {
                    sizes {
                      large {
                        source_url
                      }
                      medium {
                        source_url
                      }
                    }
                  }
                }
              }
            }
            title
            wordpress_id
          }
        }
      }
    }    
  `}
    render={data => {
      const cover = data && 
        data.allWordpressAcfOptions &&
        data.allWordpressAcfOptions.edges &&
        data.allWordpressAcfOptions.edges[0] &&
        data.allWordpressAcfOptions.edges[0].node &&
        data.allWordpressAcfOptions.edges[0].node.options &&
        data.allWordpressAcfOptions.edges[0].node.options.carousel_content
        ? data.allWordpressAcfOptions.edges[0].node.options.carousel_content : null

      const slides = []
      data && 
        data.allWordpressWpVoice &&
        data.allWordpressWpVoice.edges &&
        data.allWordpressWpVoice.edges.map(
          (prop, i) => {
            // console.log(prop.node)
          //   const wordpress_id = prop.node.acf.voice.wordpress_id
          //   const nodes = data.allWordpressWpVoice.edges;
          //   // console.log(wordpress_id)
          //   // console.log(nodes)
          //   const result = nodes.find( ({ node }) => {
          //     return node.wordpress_id === wordpress_id
          //   } );
            const node = prop.node;
            const background_url = node.acf.images.slide_background.media_details.sizes.large.source_url
            const foreground_url = node.acf.images.slide_foreground.media_details.sizes.large.source_url

            let slide = {
              // audio: node.acf.audio.source_url,
              background: background_url,
              foreground: foreground_url,
              title: node.title,
              // url: node.wordpress_id
            }
          slides.push(slide)
          }
        )
      return <Carousel cover={cover} slides={slides} />
    }}
  ></StaticQuery>
)

export default StoriesCarousel

