// See: https://github.com/robmarshall/gatsby-gravityforms-component
import React from 'react'
// import Layout from 'src/components/layout/layout'

// import Player from 'src/components/player/player'
import Player from 'src/components/listen-player/player'
import Header from 'src/components/listen-header/header'
import Head from 'src/components/seo/seo'
import Div100vh from 'react-div-100vh'

import './listen.scss'

Array.prototype.getRandom = function(cut){
    var i= Math.floor(Math.random()*this.length);
    if(cut && i in this){
        return this.splice(i, 1)[0];
    }
    return this[i];
}

const colors = [
    'bg-purple',
    'bg-pink',
    'bg-blue',
    'bg-green',
    'bg-yellow'
]

export default class audioPage extends React.Component {
    constructor(){
        super();
        this.state = {
            bgColor: colors.getRandom()
        }
        this.doParentToggle = this.doParentToggle.bind(this);
    }

    componentDidMount() {
        // console.log(this.props)
        // console.log(this.state)
        // this.doParentToggle()
    }

    doParentToggle() {
        this.setState({
            bgColor: colors.getRandom()
        })
    }
    
    render () {
        const bgColor = colors.getRandom();
        return (
            <>
            <Head title="18x18 by YWCA" />
            <Div100vh className={"page page-listen " + this.state.bgColor}>
                <Header />
                <Player presets={this.props.location.state} parentToggle={this.doParentToggle} />
            </Div100vh>
            </>
        )
    }
}