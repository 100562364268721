import React from "react"
import { Link } from 'gatsby'

import logo from 'src/img/logo.svg'
import Primary from './MenuPrimary'

import './navbar/navbar.scss'

const Navbar = ({ className }) => {
    return (
    <header className={"navbar container grid-xl " + className}>
    <section className="navbar-section">
      <Link to="/" className="navbar-brand">
        {/* <img src={logo} alt="YWCA" /> */}
      </Link>
    </section>
    <section className="navbar-section navbar-section-primary hide-off-canvas-breakpoint">
        <Primary className="nav-horizontal" />
    </section>
  </header>
  )}

  export default Navbar
