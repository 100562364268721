import React, { Component } from 'react';
import { Link } from 'gatsby'

import Mic from 'src/components/buttons/mic/mic'

import './welcome.scss'

class Welcome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            disabled: true,
        };
        this.handleCheck = this.handleCheck.bind(this);
        this.handleClick = this.handleClick.bind(this);
      }

    handleClick () {
        this.props.next({ terms: this.state.checked })
    }

    handleCheck (event) {
        this.setState({ checked: event.target.checked });
    }

    render () {
        return (
            <div className="section section-welcome container">
                <div className="columns">
                    <div className="column col-8 col-mx-auto">
                        <h1>How did you feel at 18?</h1>
                        <p>Give us your answer in <b>one word.</b></p>
                        <Mic disabled={!this.state.checked} clicked={this.handleClick} />
                        <label className="form-checkbox">
                            <input type="checkbox" checked={this.state.checked} onChange={this.handleCheck}/>
                            <i className="form-icon"></i> I agree to <Link to="/terms-and-conditions/">publish my voice</Link>
                        </label>
                    </div>
                </div>
            </div>
        )
    }
}

export default Welcome