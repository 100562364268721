import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'src/components/layout/layout'
import Head from 'src/components/seo/seo'
import HomeTemplate from './home'
import Navbar from 'src/components/Navbar'
import SpinnerContainer from '../components/spinner/SpinnerContainer'

import './page/page.scss'

export const PageTemplate = ({ title, content }) => {
  return (
    <section className="section section-page">
      <div className="container grid-md">
        <h1 className="title">
          {title}
        </h1>
      </div>
      <div className="container grid-md">
        <div
          className="content content-entry"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </section>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}

// export default class IndexPage extends React.Component {

class Page extends React.Component {
  
  state = {
    loading: true,
    error: false,
    id: null,
    fetchedData: {
      title: null,
      content: null
    }
  }

  componentDidMount() {

    // console.log(this.props)

    const slug = this.props.pageContext.slug ? this.props.pageContext.slug : this.props.uri

    fetch(process.env.API + "/wp/v2/pages?slug=" + slug)
      .then(response => {
        // console.log(response)
        return response.json()
      })
      .then(json => {
        this.setState({
          fetchedData: {
            title: json[0].title.rendered,
            content: json[0].content.rendered
          },
          loading: false
        })
      })
  }

  render() {

    const { loading, fetchedData } = this.state
    const { content, title } = fetchedData
    const { data, path } = this.props
    const { wordpressPage: page } = data

    return (
      <div>
      <SpinnerContainer showSpinner={content ? false : true} />
      <Layout>
        <Navbar className={path === "/" ? "navbar-float" : null} />
          {path === "/" ? 
            <HomeTemplate title={title} content={content} /> :
            <PageTemplate title={title} content={content} />
          }
        </Layout>
      </div>
      )
  }

}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
    }
  }
`
