import React from 'react'
import PropTypes from 'prop-types'
// import Helmet from 'react-helmet'
import Head from 'src/components/seo/seo'
import { graphql, Link } from 'gatsby'
import Layout from 'src/components/layout/layout'
import Navbar from 'src/components/Navbar'

import './post/post.scss'
import StoriesHero from 'src/components/stories-hero/hero'

export const BlogPostTemplate = ({
  content,
  categories,
  excerpt,
  tags,
  title,
  date,
  author,
  featured,
  bg_colour,
}) => {
  return (
    <section className={`section section-story section-colour-${bg_colour}`}>
      <div className="container content">
        <div className="columns">
          <div className="column col-10 col-mx-auto entry">
  
            <StoriesHero title={title} source_url={featured.source_url} excerpt={excerpt} />
  
            <div className="entry-content" dangerouslySetInnerHTML={{ __html: content }} />
            <div style={{ marginTop: `4rem` }}>
              {/* {author && author.length ? (
              <p>
                {date} - posted by{' '}
                <Link to={`/author/${author.slug}`}>{author.name}</Link>
              </p>
              ) : null}
              {categories && categories.length ? (
                <div>
                  <h4>Categories</h4>
                  <ul className="taglist">
                    {categories.map(category => (
                      <li key={`${category.slug}cat`}>
                        <Link to={`/categories/${category.slug}/`}>
                          {category.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              {tags && tags.length ? (
                <div>
                  <h4>Tags</h4>
                  <ul className="taglist">
                    {tags.map(tag => (
                      <li key={`${tag.slug}tag`}>
                        <Link to={`/tags/${tag.slug}/`}>{tag.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null} */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
}

// const BlogPost = ({ data }) => {
class BlogPost extends React.Component {

  state = {
    loading: true,
    error: false,
    id: null,
    fetchedData: {
      post: null
    }
  }

  componentDidMount() {

    // const slug = this.props.uri
    const slug = this.props.slug

    // console.log(slug)
    // console.log(this.props.slug)

    fetch(process.env.API + "/wp/v2/posts?slug=" + slug + '&_embed')
      .then(response => {
        // console.log(response)
        return response.json()
      })
      .then(json => {

        console.log(json)
        // console.log(json[0]._embedded)

        let posts = json.map(item => {
          return {
            acf: item.acf,
            // author: {
            //   name: item._embedded.author[0].name,
            //   slug: item._embedded.author[0].slug
            // },
            // author: null,
            // categories: null,
            content: item.content.rendered,
            date: null,
            excerpt: item.excerpt.rendered,
            featured_media: item._embedded['wp:featuredmedia'] ? {source_url: item._embedded['wp:featuredmedia'][0].source_url} : {source_url: null}, 
            id: item.id,
            slug: item.slug,
            // tags: item.tags,
            title: item.title.rendered
          }
        })

        // console.log(posts)

        this.setState({
          fetchedData: {
            post: posts[0]
          },
          loading: false
        })
      })
  }

  render () {

    const { post } = this.state.fetchedData

    const { data } = this.props
    // const { wordpressPost: post } = data

    console.log(post);
    // console.log(fetchedData.post);
    if(!post) return null

    return (
      <Layout>
        {/* <Head title={`${post.title} for 18x18 by YWCA`} /> */}
        <Navbar className="navbar-float" />
        <BlogPostTemplate
          content={post.content}
          // categories={post.categories}
          // tags={post.tags}
          title={post.title}
          date={post.date}
          // author={post.author}
          featured={post.featured_media}
          excerpt={post.excerpt}
          />
      </Layout>
    )
  }
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query BlogPostByID($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      date(formatString: "MMMM DD, YYYY")
      #categories {
      #  name
      #  slug
      #}
      #tags {
      #  name
      #  slug
      #}
      #author {
      #  name
      #  slug
      #}
      featured_media {
        source_url
      }
    }
  }
`
