import React from 'react'

import './hero.scss'

const StoriesHero = ({ title, excerpt, source_url }) => (
    <div className="hero hero-lg bg-dark bg-img" style={{backgroundImage: `url(${source_url})`}}>
        <div className="hero-body">
            <h2 className="subtitle">18x18</h2>
            <h1>{title}</h1>
            {/* {excerpt && excerpt.length ? (
            <div className="excerpt" dangerouslySetInnerHTML={{ __html: excerpt }} />
            ) : null} */}
        </div>
    </div>
)

export default StoriesHero
