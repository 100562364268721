import React from 'react'

import Spinner from './spinner'

const styles = {
  height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#39004a",
    flexDirection: "column"
}

const SpinnerContainer = ({ showSpinner = false })  => {
    return showSpinner ?  
      <div style={styles}>
        <Spinner />
        <h1>Loading...</h1>
      </div> : null
}

export default SpinnerContainer

