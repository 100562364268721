import React, { Component } from 'react';
import { Howl, Howler } from 'howler';

// import AudioAnalyser from 'src/components/audio/AudioAnalyser';
import AudioVisualiser from 'src/components/audio/AudioVisualiser';
// import Svg from 'src/components/carousels/Svg'
import Text from 'src/components/text/Text'

// import soundfile from "src/audio/strawberries.wav"
// import soundfile2 from "src/audio/coco01.wav"

import './player.scss'

import BackBtn from 'src/img/icons/bwd.svg'
import FwdBtn from 'src/img/icons/fwd.svg'
import PauseBtn from 'src/img/icons/pause.svg'
import PlayBtn from 'src/img/icons/play.svg'

import Record from 'src/components/buttons/record/record'

Array.prototype.getRandomKey = function(cut){
  var i= Math.floor(Math.random()*this.length);
  return i;
}

function decodeHTMLEntities(text) {
  var textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}

class Player extends Component {

  // audio = null

  constructor(props) {
    super(props);
    this.state = {
      audio: null,
      audioData: new Uint8Array(0),
      currentTrack: null,
      currentTrackId: null,
      trackCount: null,
      // tracks: [],
      loading: true,
      playing: false,
      hideRec: false
    };
    this.capture = this.capture.bind(this)
    this.clearState = this.clearState.bind(this)
    this.getTrack = this.getTrack.bind(this)
    this.togglePlayer = this.togglePlayer.bind(this);
    this.playAudio = this.playAudio.bind(this);
    this.stopAudio = this.stopAudio.bind(this);
    this.skipBck = this.skipBck.bind(this);
    this.skipFwd = this.skipFwd.bind(this);

    this.doParentToggleFromChild = this.doParentToggleFromChild.bind(this);

  }

  capture() {
    // Create analyzer
    var analyser = Howler.ctx.createAnalyser();

    // Connect master gain to analyzer
    Howler.masterGain.connect(analyser);

    // Connect analyzer to destination
    analyser.connect(Howler.ctx.destination);

    // console.log(analyser)

    // Creating output array (according to documentation https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Visualizations_with_Web_Audio_API)
    analyser.fftSize = 2048;
    var bufferLength = analyser.frequencyBinCount;
    var dataArray = new Uint8Array(bufferLength);

    // Get the Data array
    analyser.getByteTimeDomainData(dataArray);

    // Display array on time each 3 sec (just to debug)
    setInterval(function(){ 
        analyser.getByteTimeDomainData(dataArray);
        this.setState({ audioData: dataArray });
    }.bind(this), 100)
  }

  componentDidMount() {
    // console.log('componentDidMount')
    // console.log(this.props)
    this.props.presets ? this.setState({currentTrackId: this.props.presets.setTrack, hideRec: this.props.presets.hideRec}) : null
    this.audio = new Audio();
    this.fetchPlaylist()
    window.addEventListener('beforeunload', this.clearState)
  }

  componentWillUnmount () {
    this.clearState()
    window.removeEventListener('beforeunload', this.clearState)
  }

  clearState() {
    console.log('clearing')
    if(this.Sound) this.Sound.unload();
    this.state = {
      audio: null,
      audioData: new Uint8Array(0),
      currentTrack: null,
      currentTrackId: null,
      trackCount: null,
      loading: true,
      playing: false,
      hideRec: false
    };
    this.tracks = []
  }

  doParentToggleFromChild(){
    this.props.parentToggle(true)
  }

  playAudio() {

    console.log('play')

    this.audio.src = this.state.currentTrack.audio //this.tracks[this.state.currentTrack].audio //soundfile
    // const stream = this.audio.captureStream();
    // this.audio.play() // stream now has input

    // console.log(this.audio.src)

    this.Sound = new Howl({
      autoplay: true,
      src: this.audio.src,
      volume: 0,
      // loop: true
    });

    // this.Sound.play()
    this.setState({playing: true})

    this.Sound.on('end', function(){
      this.skipFwd()
    }.bind(this));

    // this.Sound.on('loaderror', function(){
    //   this.skipFwd()
    // }.bind(this));

    this.Sound.on('play', function(){
      this.Sound.fade(0, .5, 1000)
      this.capture()
      // console.log(Howler)
    }.bind(this));

    // this.Sound.on('playerror', function(){
    //   this.skipFwd()
    // }.bind(this));

  }

  stopAudio() {

    console.log('stop')

  //  this.audio.src = null

   this.Sound.stop()

    this.setState({ 
      audio: null,
      playing: false
    });
  }

  togglePlayer() {
    console.log(this.state.playing)
    if (this.state.playing) {
      this.stopAudio();
    } else {
      this.playAudio();
    }
  }

  fetchPlaylist() {
    console.log('fetching playlist')
    //https://api18at18.staging.osynlig.nz/wp-json/wp/v2/voice

    fetch(process.env.API + "/wp/v2/voice/?per_page=100")
      .then(response => {
        // console.log(response)
        return response.json()
      })
      .then(json => {

        // console.log(json)
        // console.log(json[0]._embedded)

        this.tracks = json.map(item => {
          // console.log(item)
          return {
            id: item.id,
            audio: item.acf.audio,
            title: decodeHTMLEntities(item.title.rendered)
          }
        })
        // console.log(this.tracks)

        let trackId = 0;
        if(this.state.currentTrackId) {
          /** keep current */
          trackId = this.state.currentTrackId
        } else {
          /** set a random track */
          const key = this.tracks.getRandomKey()
          trackId = this.tracks[key].id
        }
        console.log('trackId: ',trackId)

        /** set current track */
        const track = this.getTrack(trackId)

        this.setState({
          currentTrack: track,
          currentTrackId: trackId,
          loading: false,
          trackCount: this.tracks.length,
          tracks: this.tracks,
        })

        console.log('tracks loaded')
        console.log('currentTrackId: ' + this.state.currentTrackId)

      })

  }

  getCurrent() {
    const id = this.state.currentTrack;
    const track = this.tracks.find(item => item.id == id )
    console.log('track: ', track)
    return track.audio
  }

  getTrack(id) {
    // console.log(this.tracks)
    const track = this.tracks.find(item => item.id == id )
    // console.log('track: ', track)
    return track
  }

  getTrackKey(id) {
    // console.log(this.tracks)
    const index = this.tracks.findIndex(item => item.id == id )
    // console.log('track: ', track)
    return index
  }

  getNext() {
    const index = this.getTrackKey(this.state.currentTrackId)
    let next = index + 1
    if( !this.tracks.hasOwnProperty( next )) {
      next = 0
    }
    return this.tracks[next]
  }

  getPrev() {
    const index = this.getTrackKey(this.state.currentTrackId)
    let prev = index - 1
    if( !this.tracks.hasOwnProperty( prev )) {
      prev = this.tracks.length - 1
    }
    return prev
  }

  skipBck() {

    if(this.state.audio) this.stopAudio();

    const prev = this.getPrev()
    // console.log(prev)
    this.setState({
      currentTrack: prev,
      currentTrackId: prev.id
    });

    this.doParentToggleFromChild()
  }

  skipFwd() {

    if(this.state.playing) this.stopAudio();

    const next = this.getNext()
    // console.log(next)
    this.setState({
        currentTrack: next,
        currentTrackId: next.id
      },
      this.doParentToggleFromChild()
    );
    this.playAudio()
  }

  render() {
    return (
      // <div className="section section-player">
        <>
        <div className="canvas-wrapper">
          {/* {this.state.audioData ? <AudioAnalyser audio={this.state.audioData} /> : <canvas className="visualizer paused" />} */}
          {this.state.playing ? <AudioVisualiser audioData={this.state.audioData} /> : <canvas className="visualizer paused" />}
          {this.state.playing ?  <Text text={this.state.currentTrack.title} /> : <Text text='How did you feel at 18?' /> }
        </div>

        <div className="container controls-wrapper">
          <div className="columns">
            <div className="column col-sm-3 col-4"></div>
            <div className="column col-sm-6 col-4">

              <div className="controls">
                {/* <button onClick={this.skipBck} className="btn btn-action s-circle" disabled={this.state.loading ? true : false}><BackBtn /></button> */}
                <button onClick={this.togglePlayer} className="btn btn-action s-circle btn-lg" disabled={this.state.loading ? true : false}>
                  {this.state.playing ? <PauseBtn /> : <PlayBtn />}
                </button>
                {/* <button onClick={this.skipFwd} className="btn btn-action s-circle" disabled={this.state.loading ? true : false}><FwdBtn /></button> */}
              </div>

            </div>
            <div className="column col-sm-3 col-4 record-wrapper">
              <Record className={this.state.hideRec ? "d-invisible" : "d-visible"} />
            </div>
            <div className="column col-12 counter">
            {this.state.trackCount ? this.state.trackCount + " voices" : "Loading..." }
            </div> 
          </div>
        </div>
        </>
      // </div>
    )
  }
}

export default Player;
