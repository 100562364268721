import React from 'react'

import './timer.scss'

export default class Timer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      minutes: 0,
      seconds: 5,
    }
  }

  componentDidMount() {
    this.props.active ? this.start() : null
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active !== this.props.active) {
      this.props.active ? this.start() : this.stop()
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  onEnd() {
    this.stop()
    this.props.onEnd()
  }
  
  start() {

    this.setState({active: true})

    this.interval = setInterval(() => {
        const { seconds, minutes } = this.state

        if (seconds > 0) {
            this.setState(({ seconds }) => ({
                seconds: seconds - 1
            }))
        }
        if (seconds === 0) {
            if (minutes === 0) {
              this.onEnd()
            } else {
                this.setState(({ minutes }) => ({
                    minutes: minutes - 1,
                    seconds: 59
                }))
            }
        } 
    }, 1000)
  }

  stop() {
    this.setState({active: false})
    clearInterval(this.interval)
  }

  toggle() {
    if(this.props.active) {
      this.start()
    } else {
      this.stop()
    }
  }

  render() {
    const { active, minutes, seconds } = this.state
    return (
      <div className={"timer" + (active ? " active" : "")}>
        <h2>Recording</h2>
        <h1>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h1>
      </div>
    )
  }
}