import React from "react"
import { gsap, CSSPlugin, TimelineLite } from 'gsap'
gsap.registerPlugin(CSSPlugin)
import { Textfit } from 'react-textfit';

// import SVG from './Svg'
// import Slide from './StorySlide'
// import Text from 'src/components/text/Text'
// import TextPlugin from "gsap/TextPlugin";
import Visualiser from './StaticVisualizer';

class Carousel extends React.Component {

    constructor(props){
        super(props);

        // reference to the DOM node
        this.slides = this.props.slides;
        this.text = null;

        // reference to the animation
        this.myTween = new TimelineLite({
            delay: 0,
            repeat: 1,
            yoyo: false
        });

        // this.background = []

    }

    componentDidMount() {
        this.slides.map((slide, key) => {
            // console.log(key)
            let next = key+1;
            if(next == this.slides.length) next = 0;

            if(key+1 < this.slides.length) {
                this.myTween
                .to( this.refs['foreground-'+(key)], 2, {opacity: 0}, '+=2')
                .to( this.refs['title-'+(key)], 2, {scale: 1.3}, '-=2' )
                .to( this.refs['title-'+(key)], 1, {opacity: 0}, '-=1' )
                .to( this.refs['background-'+(key)], 1, {opacity: 0})
                .to( this.refs['background-'+(next)], 1, {opacity: 1}, '-=1' )
                .to( this.refs['foreground-'+(next)], 1, {opacity: 1} )
                .to( this.refs['title-'+(next)], 1, {opacity: 1} )
            }
        })

    }

    render() {
      const slides = this.props.slides
      const cover = this.props.cover
      return (
            <div className="carousel carousel-stories">
                <div className="content" dangerouslySetInnerHTML={{ __html: cover }} />

                {/* slides */}
                <div className="slides">
                    <div className="slide">
                        {/* Backgrounds */}
                        <div className="backgrounds">
                            {slides.map((slide, key) =>
                                <div key={'background-'+key} ref={'background-'+key} className="background img-responsive" style={{ backgroundImage: 'url(' + slide.background + ')' }} />
                            )}
                        </div>

                        {/* Visualizer */}
                        {/* <Visualiser /> */}

                        {/* Titles */}
                        <div className="titles">
                            {slides.map((slide, key) =>
                            <div key={'title-'+key} ref={'title-'+key} className="title">
                                <Textfit max={999} mode="single" className="text">
                                    {slide.title}
                                </Textfit>
                            </div>
                            )}
                        </div>

                        {/* Foregrounds */}
                        <div className="foregrounds">
                            {slides.map((slide, key) =>
                                <div key={'foreground-'+key} ref={'foreground-'+key} className="foreground img-responsive" style={{ backgroundImage: 'url(' + slide.foreground + ')' }} />
                            )}
                        </div>

                    </div>
                </div>
            </div>
        )
    }
  }

  export default Carousel;