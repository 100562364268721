import React, { Component } from 'react';
// import { Link } from 'gatsby'
import AudioUploader from 'src/components/audio/AudioUploader'
import Spinner from 'src/components/spinner/spinner'
import Text from 'src/components/text/Text'

import './loader.scss'



class Loader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            text: null,
            transcribing: true,
            uploading: false
        }
        this.Uploader = new AudioUploader
        this.upload = this.upload.bind(this)
        // console.log(this.props)
    }

    componentDidMount() {
        console.log('loader did mount')
        console.log('test: ', this.props.data.title)
        // this.Uploader.startUpload(this.props.file) 
        this.setState({text: this.props.data.title})
        this.props.data.complete && this.props.data.file ? this.upload() : null  
    }

    componentDidUpdate(prevProps) {
        console.log('loader did update');
        console.log(this.props)

        if (prevProps.data.title !== this.props.data.title) {
            this.setState({text: this.props.data.title})
        }
        this.props.data.complete  && this.props.data.file && !this.state.uploading ? this.upload() : null  
    }

    async upload() {
        this.setState({uploading: true})
        const post = await this.Uploader.startUpload(this.props.data)
        console.log(post)
        this.props.redirect(post.id)
    }

    render () {
        return (
            <div className="container section section-loader">
                <div className="columns">
                    <div className="column col-10 col-mx-auto">
                        <Text text={this.state.text} />
                    </div>
                </div>

                <div className="columns">
                    <div className="column col-8 col-mx-auto">
                        <Spinner />
                        <p className={this.state.uploading ? "d-visible" : "d-invisible"}><b>Adding your word to the collection</b></p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Loader