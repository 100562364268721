import React from "react"
import AudioVisualiser from 'src/components/audio/AudioVisualiser';

class Visualizer extends React.Component {

    constructor(props) {
        super(props);
        this.state = { audioData: new Uint8Array(0) };
        this.setData = this.setData.bind(this);
        this.tick = this.tick.bind(this);
      }

    componentDidMount() {
        setTimeout(function () {
            this.interval = setInterval(() => {
                this.setData()
            }, 0.4)
        }.bind(this), 1000);

        // this.setData()
        this.rafId = requestAnimationFrame(this.tick);

        setTimeout(function () {
            clearInterval(this.interval)
            this.dataArray = new Uint8Array(0);
        }.bind(this), 2000);
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }    

    setData() {
        // randomly generated N = 40 length array 0 <= A[N] <= 39
        const random = Array.from({length: 125}, () => Math.floor(Math.random() * 255));
        // console.log(random)
        this.dataArray = new Uint8Array(random);
        // console.log(this.dataArray)
        // this.setState({ audioData: this.dataArray });
    }

    tick() {
        // this.analyser.getByteTimeDomainData(this.dataArray);
        this.setState({ audioData: this.dataArray });
        this.rafId = requestAnimationFrame(this.tick);
      }

    render() {
        if(this.state.audioData) {
            return <AudioVisualiser audioData={this.state.audioData} />
        } else {
            return null;
        }
    }
}

export default Visualizer