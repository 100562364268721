import React from "react"
import { Link } from 'gatsby'

import Back from 'src/img/icons/arrow_back.svg'
// import logo from 'src/img/logo.svg'
// import Primary from './MenuPrimary'

import './header.scss'

const Header = ({ className }) => {
    return (
    <header className={"navbar container grid-xl " + className}>
        <section className="navbar-section">
            <Link to="/" className="navbar-back">
                {/* <img src={'/icons/arrow_back.svg'} alt="Back" /> */}
                <Back />
           </Link>
        </section>
        <section className="navbar-center">
            <Link to="/" className="navbar-brand">
                {/* <img src={logo} alt="YWCA" /> */}
            </Link>
        </section>
        <section className="navbar-section">
        </section>
    </header>
  )}

  export default Header
