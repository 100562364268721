import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'src/components/layout/layout'
import Navbar from 'src/components/Navbar'
import StoriesCarousel from 'src/components/carousels/StoriesCarousel'
import Head from 'src/components/seo/seo'

import './page/home.scss'

const HomeTemplate = ({ title, content }) => {``
  return (
    <section className="section section-page section-home">
      <div className="_conatiner">
        <StoriesCarousel />
      </div>
      <div
        className="content entry-content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </section>
  )
}

HomeTemplate.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}

// export default class IndexPage extends React.Component {

class Page extends React.Component {
  
  state = {
    loading: true,
    error: false,
    id: null,
    fetchedData: {
      title: null,
      content: null
    }
  }

  componentDidMount() {

    // console.log(this.props)

    const slug = this.props.pageContext.slug ? this.props.pageContext.slug : this.props.uri

    fetch(process.env.API + "/wp/v2/pages?slug=" + slug)
      .then(response => {
        // console.log(response)
        return response.json()
      })
      .then(json => {
        this.setState({
          fetchedData: {
            title: json[0].title.rendered,
            content: json[0].content.rendered
          },
          loading: false
        })
        // console.log(json[0])
      })
  }

  render() {

    const { loading, fetchedData } = this.state

    const { data } = this.props
    const { wordpressPage: page } = data

    // console.log(this.props);

      return (
        <Layout>
          {/* <Head title={`${fetchedData.title} | 18x18 by YWCA`} /> */}
          <Navbar className="navbar-float" />
          <HomeTemplate title={fetchedData.title} content={fetchedData.content} />
        </Layout>
      )
  }

}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query HomeById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
    }
  }
`
