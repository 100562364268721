import React, { Component } from 'react'
import Auth from 'src/services/auth'
import Request from 'src/services/request'

// import soundfile from "../../../static/audio/strawberries.wav"
// import test from "../../../static/audio/strawberries.wav"

const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

class Uploader extends Component {

    token = null
    media_id = null

    wav = 'https://api.18at18.loc/strawberries.wav'

    post = {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, cors, *same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "omit", // include, *same-origin, omit
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "follow", // manual, *folslow, error
        referrer: "client", // no-referrer, *client
        // body: JSON.stringify(data), // body data type must match "Content-Type" header
    }
    url = process.env.API + '/wp/v2/voice'

    data = {
        "title": null,
        "content": null,
        "status": "publish",
        "fields": {
            "audio": this.media_id, // get from first post
            "terms": this.terms,
            "generation": this.generation
        }
    }

  constructor(props) {
    super(props);
    this.state = {
      uploading: false
    };
  }

  test() {
    console.log('test run')
  }

  async componentDidMount() {
    console.log('uploader did mount')
    // get a fresh token on mount
    this.token = await Auth.getToken()
    console.log(this.props.audio)
  }

  async fetchPosts() {
    const response = await fetch(this.url);
    const json = await response.json();
    // console.log(json);
  }

  async setPosts() {
    const audio = {
      // blob
    }
    var post = this.post
    post.body = JSON.stringify(data)
    const response = await fetch(this.url, post);
    const json = await response.json();
    console.log(json);
  }

  async startUpload(data) {

    // console.log(data)
    /** 
    data: {
      terms: booleen,
      generation: integer,
      title: string,
      file: File,
      complete: booleen
    }
    */

    this.data.title = data.title
    this.data.fields.terms = data.terms
    this.data.fields.what_generation_are_you = data.generation

    // this.setState({ uploading: true })
    // console.log('upload started')
    // console.log(blob)
    // const audio = await toBase64(blob)
    // var array = new File([""], test);

    // this.fetchPosts();
    // this.setPosts();
    // let response = await fetch(this.wav, {"mode": "no-cors"});
    // const arrayBuffer = await response.arrayBuffer();
    // const base64String = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
    // const audio = 'data:audio/wav;base64,' + base64String
    // console.log('audio: ' + audio)

    console.log(data.file);
    let response = await Request.createMedia(data.file)
    const media = await response.json();
    // console.log(media.source_url)
    this.media_id = await media.id
    this.data.fields.audio = this.media_id;
    response = await Request.createPost(this.data)
    const post = await response.json();
    // console.log(post)

    return post

    // const url = new URL(post.link)
    // console.log(url.pathname)
    // return url.pathname

  }

  stopUpload() {
    this.setState({ uploading: false })
  }

  toogleUpload() {
    if (this.state.uploading) {
      this.stopUpload()
    } else {
      this.startUpload()
    }
  }

  render() {
    return (
      <h1>Hello</h1>
    )
  }
}

export default Uploader;