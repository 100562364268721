import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'src/components/layout/layout'
import Navbar from 'src/components/Navbar'
import StoriesCarousel from 'src/components/carousels/StoriesCarousel'
import SpinnerContainer from '../components/spinner/SpinnerContainer'

import './page/home.scss'

const WideTemplate = ({ title, content }) => {``
  return (
    <section className="section section-page section-home">
      {/* <div className="_conatiner">
        <StoriesCarousel />
      </div> */}
      <div
        className="content entry-content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </section>
  )
}

WideTemplate.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}

// export default class IndexPage extends React.Component {

class Page extends React.Component {
  
  state = {
    loading: true,
    error: false,
    id: null,
    fetchedData: {
      title: null,
      content: null
    },
  }

  componentDidMount() {

    const slug = this.props.pageContext.slug ? this.props.pageContext.slug : this.props.uri

    fetch(process.env.API + "/wp/v2/pages?slug=" + slug)
      .then(response => {
        return response.json()
      })
      .then(json => {
        this.setState({
          fetchedData: {
            title: json[0].title.rendered,
            content: json[0].content.rendered
          },
          loading: false
        })
      })
  }

  render() {

    const { loading, fetchedData } = this.state

    const { data } = this.props
    const { wordpressPage: page } = data

      return (
        <Layout>
          <Navbar className="navbar-float" />
          <SpinnerContainer showSpinner={this.state.fetchedData.content ? false : true} />
          <WideTemplate title={fetchedData.title} content={fetchedData.content} /> 
        </Layout>
      )
  }

}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query WideById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
    }
  }
`
