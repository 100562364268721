import React from 'react'
// import Helmet from 'react-helmet'
import Head from 'src/components/seo/seo'

// import FooterMenu from './MenuFooter'
// import Navbar from 'src/components/Navbar'
import Primary from 'src/components/MenuPrimary'
import Sponsors from 'src/components/sponsors/sponsors'
// import MenuFooter from 'src/components/MenuFooter'
// import MenuSocial from 'src/components/MenuSocial'
import Footer from 'src/components/footer/footer'

// import './all.sass'
import 'src/styles/wp_v2/_wp.scss'
import 'src/styles/spectre.scss'
// import 'src/styles/spectre-exp.scss'
// import 'src/styles/spectre-icons.scss'

import './layout.scss'

const TemplateWrapper = ({ children }) => {
  return (
  <div className="off-canvas">
    <Head title="18x18 by YWCA" />
    {/* <!-- off-screen toggle button --> */}
    <a className="off-canvas-toggle btn btn-primary btn-action show-lg s-circle" href="#sidebar-id">
      <i className="icon icon-menu"></i>
    </a>

    <div className="off-canvas-content">
      {/* <!-- off-screen content --> */}
      {/* <Navbar /> */}
      <div className="entry _container _grid-xl">{children}</div>
      <Sponsors />
      <Footer />
    </div>

    <div id="sidebar-id" className="off-canvas-sidebar">
      {/* <!-- off-screen sidebar --> */}
      <Primary />
    </div>

    <a className="off-canvas-overlay" href="#close"></a>


  </div>
)}

export default TemplateWrapper
