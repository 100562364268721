// import React from 'react'

/* handle WP authentication */
class Auth {

    token = null

    fetchToken = async () => {
        console.log(process.env.API);
        return await fetch({
            method: 'GET',
            url: process.env.API + '/jwt-auth/v1/token',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "username": process.env.JWT_USER,
                "password": process.env.JWT_PASSWORD
            }),
          });
    }

    getToken = () => {
        if(this.token) {
            return this.token
        } else {
            return this.setToken()
        }
    }

    setToken = async () => {

        const url = process.env.API + '/jwt-auth/v1/token'

        // console.log(url)
        // console.log(process.env.JWT_USER)
        // console.log(process.env.JWT_PASSWORD)

        const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "username": process.env.JWT_USER,
                "password": process.env.JWT_PASSWORD
            }),
        })
        const json = await response.json()
        const token = await json.token
        this.token = token
        return token
    }

}

export default new Auth()