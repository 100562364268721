// See: https://stackoverflow.com/questions/50431236/use-getusermedia-media-devices-in-reactjs-to-record-audio
import React, { Component } from 'react';
import MediaRecorder from 'audio-recorder-polyfill'
// const MediaRecorder = typeof window !== `undefined` ? require("audio-recorder-polyfill") : null

// import AudioUploader from './AudioUploader'

class AudioRecorder extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      blob: null 
    };
    this.audio = new Audio()
  }

  // static encodeWAV = (samples) => {
  //   var buffer = new ArrayBuffer(44 + samples.length * 2);
  //   var view = new DataView(buffer);
  
  //   writeString(view, 0, 'RIFF');
  //   view.setUint32(4, 32 + samples.length * 2, true);
  //   writeString(view, 8, 'WAVE');
  //   writeString(view, 12, 'fmt ');
  //   view.setUint32(16, 16, true);
  //   view.setUint16(20, 1, true);
  //   view.setUint16(22, 1, true);
  //   view.setUint32(24, recordSampleRate, true);
  //   view.setUint32(28, recordSampleRate * 2, true);
  //   view.setUint16(32, 2, true);
  //   view.setUint16(34, 16, true);
  //   writeString(view, 36, 'data');
  //   view.setUint32(40, samples.length * 2, true);
  //   floatTo16BitPCM(view, 44, samples);
  
  //   return view;
  // }

  // async componentDidMount() {
  //   const stream = await navigator.mediaDevices.getUserMedia({audio: true});
  //   // show it to user
  //   this.audio.src = window.URL.createObjectURL(stream);
  //   this.audio.play();
  //   // init recording
  //   this.mediaRecorder = new MediaRecorder(stream);
  //   // init data storage for video chunks
  //   this.chunks = [];
  //   // listen for data from media recorder
  //   this.mediaRecorder.ondataavailable = e => {
  //     if (e.data && e.data.size > 0) {
  //       this.chunks.push(e.data);
  //     }
  //   };
  // }

  componentDidMount() {

    // // init recording
    // this.mediaRecorder = new MediaRecorder(this.props.audio);
    // // init data storage for audio chunks
    // this.chunks = [];
    // // listen for data from media recorder
    // this.mediaRecorder.ondataavailable = e => {
    //   // add stream data to chunks
    //   this.chunks.push(e.data);
    //   // if recorder is 'inactive' then recording has finished
    //   if (this.mediaRecorder.state == 'inactive') {

    //       // convert blob to Fileso it can be saved to the server
    //       const filetype = 'webm'
    //       const filename = (new Date()).getTime() + "." + filetype
    //       const file = new File(this.chunks, filename, {type: 'audio/' + filetype});

    //       // console.log(file)

    //       // this.Uploader.startUpload(file)
    //       this.props.file(file)
    //     }
    // };
    // this.mediaRecorder.start();
    
    // See: https://github.com/ai/audio-recorder-polyfill
    this.mediaRecorder = new MediaRecorder(this.props.audio)
        
    // Set record to <audio> when recording will be finished
    this.mediaRecorder.addEventListener('dataavailable', e => {
      console.log('listening')
      // this.audio.src = URL.createObjectURL(e.data)
      // convert blob to Fileso it can be saved to the server
      console.log(e.data)
      const filetype = e.data.type
      const ext = filetype.split('/')
      const filename = (new Date()).getTime() + "." + ext[1]
      const file = new File([e.data], filename, {type: filetype});

      this.props.file(file)
    })

    // Start recording
    this.mediaRecorder.start()




  }

  componentWillUnmount() {
    console.log('recorder will unmount')
    this.mediaRecorder.stop();
    // this.saveAudio();
  }

  startRecording(e) {
    e.preventDefault();
    // wipe old data chunks
    this.chunks = [];
    // start recorder with 10ms buffer
    this.mediaRecorder.start(10);
    // say that we're recording
    this.setState({recording: true});
  }

  stopRecording(e) {
    e.preventDefault();
    // stop the recorder
    this.mediaRecorder.stop();
    // say that we're not recording
    this.setState({recording: false});
    // save the video to memory
    this.saveAudio();
  }

  saveAudio() {
    // convert saved chunks to blob
    const blob = new Blob(this.chunks);
    // generate video url from blob
    const audioURL = window.URL.createObjectURL(blob);
    // append videoURL to list of saved videos for rendering
    // const audios = this.state.audios.concat([audioURL]);
    // this.setState({audios});
    console.log(audioURL);

    Uploader.startUpload()
  }

  deleteAudio(audioURL) {
    // filter out current videoURL from the list of saved videos
    const audios = this.state.audios.filter(a => a !== audioURL);
    this.setState({audios});
  }

  saveBlob = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (blob, fileName) {
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
  }());

  render() {
    return null
  }
}

export default AudioRecorder;
