import React from 'react'
import { Link } from 'gatsby'

import MenuFooter from 'src/components/MenuFooter'
import MenuPrimary from 'src/components/MenuPrimary'
import MenuSocial from 'src/components/MenuSocial'
import Logo from 'src/img/logo.svg'

import './footer.scss'

const Footer = () => (
    <div className="footer">
        <Link to="/" className="footer-brand">
            {/* <img src={logo} alt="YWCA" className="img-responsive" /> */}
            <Logo className="img-responsive" />
        </Link>
        <MenuFooter />
        {/* <MenuPrimary /> */}
        <MenuSocial />
    </div>
)

export default Footer
