import React from 'react'

import AudioAnalyser from 'src/components/audio/AudioAnalyser';
import AudioRecorder from 'src/components/audio/AudioRecorder';
import Mic from 'src/components/buttons/mic/mic'
import Spinner from 'src/components/spinner/spinner'
import Timer from 'src/components/timer/timer'

import './recorder.scss'

export default class NewRecorder extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            audio: null,
            file: null,
            // transcript: null,
            disabled: false,
            recording: false,
            transcribing: false,
            waiting: false,
          };
        // this.handleClick = this.handleClick.bind(this);
        this.handleFile = this.handleFile.bind(this);
        // this.handleTranscription = this.handleTranscription.bind(this);
        this.stopMicrophone = this.stopMicrophone.bind(this);
        this.toggleMicrophone = this.toggleMicrophone.bind(this);
      }

    componentDidMount() {
        this.getMicrophone()
    }

    componentDidUpdate() {
        // console.log(this.state)
    }

    handleFile(file) {
        console.log('file returned to parent')
        // console.log(file)
        this.props.next({file: file})
        // if(!this.state.recording && data.transcribing === false && this.state.transcript) {
        //     // console.log('this.state.transcript')
        //     this.props.next({ 
        //         title: this.state.transcript 
        //     })
        // } 
    }

    async getMicrophone() {
        const audio = await navigator.mediaDevices.getUserMedia({
          audio: true
        });
        this.setState({ 
            audio,
            recording: true,
            transcribing: true
         });
         this.props.audio(audio) // pass to parent for transcriber to use
         console.log('audio did start')
    }

    stopMicrophone() {
        this.state.audio.getTracks().forEach(track => track.stop());
        this.setState({ 
            audio: null,
            disabled: true,
            recording: false,
            waiting: true
        });
        this.props.audio(null) // pass to parent to stop transcriber
        console.log('audio did stop')
    }

    toggleMicrophone() {
        if (this.state.audio) {
            this.stopMicrophone();
        } else {
            this.getMicrophone();
        }
    }

    render () {
        return (
            <div className="section section-recorder">
                <Timer active={this.state.recording} onEnd={this.stopMicrophone} />
                <div className="canvas-wrapper">
                    {this.state.audio ? <AudioAnalyser audio={this.state.audio} /> : <canvas className="visualizer paused" />}
                    {this.state.waiting ? <Spinner /> : <Mic active={this.state.recording} disabled={this.state.disabled} clicked={this.toggleMicrophone} />}
                </div>
                {this.state.audio ? <AudioRecorder audio={this.state.audio} file={this.handleFile} /> : null }
            </div>
        )
    }
}
