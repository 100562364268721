/** handle Requests to WP */
import Auth from './auth'

class Request {

  // static readFileAsBinary = (inputFile) => {
  //   const temporaryFileReader = new FileReader();
  
  //   return new Promise((resolve, reject) => {
  //     temporaryFileReader.onerror = () => {
  //       temporaryFileReader.abort();
  //       reject(new DOMException("Problem parsing input file."));
  //     };
  
  //     temporaryFileReader.onload = () => {
  //       // resolve(temporaryFileReader.result);
  //       resolve(temporaryFileReader.result.replace(/^data:.+;base64,/, ''));
  //     };
  //     temporaryFileReader.readAsDataURL(inputFile);
  //   });
  // }

  // expects a Web API File
  createMedia = async (file) => {

    // console.lo g(blob)

    const url = process.env.API + '/wp/v2/media'
    const token = await Auth.getToken()
    // console.log('token: ' + token)
    // base64 = data.replace('data:audio/*;base64,','')
    // console.log(blob.type)

    // const filename = (new Date()).getTime() + ".wav"
    // var file = new File([blob], filename, {type: blob.type});
    // var file = {
    //   blob,
    //   name: filename,
    //   type: blob.type
    // }

    // Since everything is set up, let’s read the Blob and store the result as Data URI
    // const binary = await Request.readFileAsBinary(file)

    // console.log(file)

    const formData = new FormData();
          formData.append('file', file);

    // const file = formData.getAll('file')[0];
    // console.log(file);
    // const tmp = new Audio(file)
    // tmp.play()

    // const src = window.URL.createObjectURL(blob)
    // const tmp = new Audio(src)
    // tmp.play()

    //generate some random number for the filename
    // var randNumber1 = Math.floor(Math.random() * 100);
    // var randNumber2 = Math.floor(Math.random() * 100);
    // const fileType = 'wav'

    // formData.append('file', data, 'audio.wav');

    // const file = await fetch('https://api.18at18.loc/strawberries.wav', {
    //   mode: 'no-cors'
    // })

    // console.log(blob)
    // console.log(blob.type)
    // console.log(filename)

    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': token ? 'Bearer ' + token : null,
        'Content-Disposition': 'attachment; filename='+file.filename,
        'Cache-Control' : 'no-cache',	
        // If you add this, upload won't work
        // 'Content-Type': 'multipart/form-data',
      },
      body: formData,
    };

    // Remove 'Content-Type' header to allow browser to add
    // along with the correct 'boundary'
    // Note: this is key for getting files to properly transfer
    delete options.headers['Content-Type'];

    return fetch(url, options);
  };
  
  createPost = async (body) => {

    console.log(body)

    const url = process.env.API + '/wp/v2/voice'
    const token = await Auth.getToken()

    return fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': token ? 'Bearer ' + token : null,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
  };

  updateMedia = async (body) => {}

  updatePost = async (body) => {}

}

export default new Request()