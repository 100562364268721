import React, { Component } from 'react';

class Survey extends Component {

    constructor(){
        super();
        this.state = {
            generation: undefined,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (event) {
        this.setState({ generation: event.target.value })
        this.props.next({ generation: event.target.value })
    }

    render () {
        return (
            <div className="section section-survey conatiner">
                <div className="columns">
                    <div className="column col-10 col-mx-auto">
                        <h4>What generation are you?</h4>
                        <div className="form-group">
                            <select className="form-select" value={this.state.generation} onChange={this.handleChange}>
                                <option>Choose an option</option>
                                <option value={1}>Baby boomer (1946-1964)</option>
                                <option value={2}>Generation X (1965-1980)</option>
                                <option value={3}>Millennial (1981-1996)</option>
                                <option value={4}>Generation Z (1997-2020)</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Survey